* {
  font-family: Poppins;
}

#catalog-subtitle {
  font-style: italic;
  padding: 25px;
}

#catalog {
  padding-left: 5%;
  padding-right: 5%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;
}

.catalog-picture {
  width: 100%;
  min-width: 100%;
  height: 100%;
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.catalog-picture-old {
  width: 100%;
  min-width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.main-picture {
  width: 40%;
  aspect-ratio: 1;
  object-fit: cover;
  margin: auto;
}

.back-button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: flex-start;
  padding-bottom: 2rem;
}

.toy-text {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 50%;
  margin: 2rem;
}

.toy-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}

.toy-title {
  font: Montserrat;
  font-weight: bold;
  width: 100%;
  text-align: left;
}

.toy-description {
  font: Montserrat;
  width: 100%;
  text-align: left;
  font-size: larger;
}

.toy-buttons {
  flex: 3;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  align-items: center;
}

.add-to-cart-button {
  flex: 1;
  border: none;
  min-width: 35%;
  width: 30%;
  color: white;
  background-color: rgba(243, 111, 57, 1);
  font-weight: bold;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
}

.plus-toy.clicked {
  background-color: #5EBFB5 !important; /* Change this color to your desired visual cue */
  color: #ffffff !important;
  transform: scale(0.9) !important;
  /* Add any other styling you want for the clicked state */
}

.build-button {
  flex: 1;
  border: none;
  min-width: 35%;
  width: 35%;
  /* max-height: 30%; */
  height: 80%;
  background-color: rgba(94, 191, 181, 0.5);
  margin-top: 10px;
  font-weight: bold;
  justify-content: center;
  display: flex;
  align-items: center;
}

.add-to-cart-button:hover {
  background-color: #5EBFB5;
  color: white;
  transform: scale(1.1);
}

.add-to-cart-button.clicked {
  background-color: #5EBFB5;
  color: #ffffff;
  transform: scale(0.9);
}

.build-button:hover {
  background-color: rgba(94, 191, 181, 1);
  color: white;
}

.button-holder {
  display: flex;
  align-items: center;
}

.button-break {
  flex: 2;
}

.catch-logo {
  padding: 40px 0px 40px 0px;
  width: 9%;
}

.toy-container {
  position: absolute;
  height: 100dvh;
  width: 100dvw;
  top: 0;
  left: 0;
  /* border: solid;
  border-color: red; */
  background-color: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 3rem;
}

.image-tile {
  position: relative;
  aspect-ratio: 1;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}
.plus-toy {
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: 5%;
  right: 5%;
  border-radius: 100%;
  border: none;
  background-color: rgb(243, 110, 57);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease; /* Apply transition to the transform property */
}

.plus-icon {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus-toy:hover {
  background-color: rgba(94, 191, 181, 1);
  color: white;
  transform: scale(1.1); /* Increase size on hover */
}

.pat {
  margin-top: 5rem;
  padding-left: 10%;
  padding-right: 10%;
}

.pat p {
  text-align: justify;
}

.pat a {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  color: white;
  background-color: rgb(243, 110, 57);
  text-decoration: none;
  font-weight: bold;
  border-radius: 7px;
  display: inline-block;
  transition: transform 0.2s ease;
}

.pat a:hover {
  background-color: #5ebfb5;
}

.pat .pat-images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 100%;
  /* border: solid; */
}

.pat .pat-images #pat1 {
  margin: 0;
  padding: 0;
  flex: 2;
  max-width: 15%;
  object-fit: contain;
  /* border: solid;
    border-color: red; */
}

.pat .pat-images .pat-images-col {
  max-width: 40%;
  flex-direction: column;
  flex: 4;
  margin: 0;
  max-height: 100%;
  /* border: solid; */
}

#pat2,
#pat3 {
  max-width: 100%;
  min-height: 45%;
  max-height: 45%;
  padding: 0;
  margin: 0;
  flex: 1;
  object-fit: cover;
  /* border: solid; */
}

#pat2 {
  margin-bottom: 8%;
  padding-bottom: 0;
}

.pat .pat-images #pat4 {
  max-width: 40%;
  flex: 4;
}

@media (max-width: 1024px) {

  .pat .pat-images {
    flex-direction: column;
    align-items: center;
  }

  .pat .pat-images #pat1 {
    max-width: 100%;
    margin-bottom: 8%;
  }

  .pat .pat-images .pat-images-col {
    max-width: 100%;
    margin-bottom: 8%;
  }

  #pat2,
#pat3 {
  max-height: 100%;
}

.pat .pat-images #pat4 {
  max-width: 100%;
}

  .catch-logo {
    width: 100px;
  }


  #catalog {
    grid-template-columns: 1fr;
  }

  .toy-text {
    width: 100%;
  }

  .toy-title {
    font-size: calc(1rem + 1vw)
  }

  .toy-description {
    font-size: .95rem
  }

  .toy-container {
    padding: 1rem;
  }

  .main-picture {
    width: 35vh;
    aspect-ratio: 1;
    object-fit: cover;
  }

  .toy-buttons {
    padding: 0 10px;
  }

  .add-to-cart-button,
  .build-button {
    width: 80%;
    margin: 5px auto;
  }

  .footer {
    padding-left: 20px;
    /* display: none; */
  }
}