* {
  font-family: "Montserrat", "sans-serif";
}

h2 {
  margin: 0;
}

.rightArrow {
  font-size: 2rem;
}

.leftArrow {
  font-size: 2rem;
}

.carousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center
}

.carouselItem {
  width: 100%;
}

.carouselItemWide {
  width: 48%;
  margin-bottom: 3rem;
}

.landing {
  background-color: #2ea397;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5rem;
  justify-content: space-between;
  height: 100dvh;
}

.tagline {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tagline h1 {
  font-size: 6.5vw;
  font-weight: 800;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.tagline p {
  font-size: 2vw;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 480px) {
  .tagline {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .tagline h1 {
    font-size: 8vw;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  .tagline p {
    font-size: 4vw;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    padding-bottom: 1rem;
  }

  .tagline svg {
    width: 4vw;
    height: 4vw;
  }
}

.canvas-container {
  width: 55%;
  margin: -1rem;
  background-size: contain;
  transition: right 0.5s ease;
  right: -200px;
}

button {
  color: black;
  background-color: transparent;
  border: none;
  margin-top: 50px;
}

#introduction,
#recent-events {
  padding: 3rem 5rem 0 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#introduction p {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.carousel-container {
  margin: 0;
  padding: 0;
}

.carousel-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 900px) {
  .landing {
    height: auto;
    background-color: #2ea397;
    display: flex;
    flex-direction: column;
  }

  .landing,
  #introduction,
  #recent-events {
    padding: 2rem;
  }

  .landing svg {
    width: 12vw;
    height: 12vw;
  }

  .canvas-container {
    margin: 0;
    margin-top: 1rem;
    width: 100%;
    background-size: contain;
  }

  .carousel {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 80vw;
    margin-top: 0px;
  }

  .carouselItemWide {
    width: 100%;
  }

  .tagline {
    width: 100%;
  }

  .button-container p {
    font-size: 5vw;
    max-width: 80%;
  }

  .carousel-image {
    height: auto;
    margin-bottom: 1rem;
  }
}

.slick-prev {
  left: 3% !important;
  z-index: 10;
}

.slick-next {
  right: 3% !important;
  z-index: 10;
}

/* .slick-arrow {
  height: 100px;
} */

.custom-slider-arrow {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 30px;
  height: 30px;
  padding: 0;
  transform: translate(0, -50%);

  cursor: pointer;

  /* color: transparent; */
  color: rgba(255, 255, 255, 0.6);
  border: none;
  outline: none;
  background: transparent;
  z-index: 10;
}

.custom-slider-arrow:hover {
  color: white;
}

.custom-prev {
  left: 25px;
}

.custom-next {
  right: 25px;
}
