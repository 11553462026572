.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form h2 {
  margin-bottom: 20px;
}

.inputLogin {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.loginButton {
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.loginButton:hover {
  background-color: #45a049;
}

.error-message {
  color: #ff0000;
}

.welcome-message {
  text-align: center;
}
