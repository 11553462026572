.transparent-button {
  background-color: transparent;
  border: none;
  color: inherit;
}

.transparent-button:hover {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: inherit;
}

.text-left {
  text-align: left;
  padding: 0;
  margin: 16px 0 0 0;
}

.btn-block {
  width: 100%;
  font-weight: bold;
}

/* position return-home class at top left of screen */
.return-home {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  margin: 10px;
}
