.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.break {
  margin-bottom: 204px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #ccc;
  --color-red: #ff0000;
  --color-blue: #0000ff;
  --color-green: #00ff00;
  --color-orange: #ffa500;
  --color-purple: #800080;
  --color-yellow: #ffff00;
  --color-pink: #ffc0cb;
  --color-brown: #a52a2a;
  --color-teal: #008080;
  --color-navy: #000080;
  --color-gold: #ffd700;
  --color-silver: #c0c0c0;
  --color-olive: #808000;
  --color-lime: #00ff00;
  --color-fuchsia: #ff00ff;
  --font-family: Arial, sans-serif;
}
