.nav-container {
  padding: 0;
  width: 100%;
  height: 3.5rem;
}

.navbar {
  font-family: "Poppins";
  max-height: 3.5rem;
  z-index: 1;
  transform: translateY(0);
  transition: transform 0.2s ease;
  transition-delay: 0s;
}

.navbar-show- {
  background-color: #2ea397;
}

.navbar-show-about {
  background-color: #f0564e;
}

.navbar-show-toys {
  background-color: #5dc0b5;
}

.navbar-show-donations {
  background-color: #0e6772;
}

.navbar-show-mediacoverage {
  background-color: #f26f3a;
}

.navbar-show-contact {
  background-color: #ce463a;
}

.navbar-hide {
  /* delay transform by 2 seconds */
  transition-delay: 2s;
  transform: translateY(-100%);
}

.navbar-delay {
  transition-delay: 0s;
}

.home-page-navbar {
  background-color: #2ea397;
}

.nav-brand,
.nav-brand:hover {
  font-size: large;
  color: white;
  width: 100px;
  font-weight: 600;
}

.nav-logo {
  max-height: 100%;
  max-width: 40px;
}

@media (max-width: 992px) {
  .toggle-button {
    /* Menu */
    order: 1;
    margin-right: 20px;
  }

  .brand-logo {
    /* Logo */
    order: 2;
    margin-left: 10px;
    margin-right: auto;
  }

  .adjust-right-nav {
    /* Shopping Cart */
    order: 3;
    margin-left: auto;
  }
}

.nav-link {
  font-size: medium;
  font-weight: 600;
  color: white;
  text-shadow: 0.1px 0.1px 0.1px rgba(0, 0, 0, 0.3);
}

.nav-link:hover {
  color: rgb(220, 220, 220);
  text-shadow: 0.1px 0.1px 0.1px rgba(0, 0, 0, 0.3);
}

.nav-link-active,
.nav-link-active:focus {
  font-weight: 800;
  color: White;
  text-shadow: 0.1px 0.1px 0.1px rgba(0, 0, 0, 0.3);
}

.nav-link-active:hover {
  font-weight: 800;
  color: rgb(220, 220, 220);
  text-shadow: 0.1px 0.1px 0.1px rgba(0, 0, 0, 0.3);
}

.addRemoveSpan {
  min-width: 60%;
  max-width: 60%;
  align-items: center;
  background-color: #AAAAAA;
  padding: 5px 20px;
  border-radius: 300px;
}  

.nav-link-alternate {
  font-size: medium;
  font-weight: 600;
  color: white;
  text-shadow: 0.1px 0.1px 0.1px rgba(0, 0, 0, 0.3);
}

.nav-link-alternate:hover {
  color: rgb(235, 235, 235);
  text-shadow: 0.1px 0.1px 0.1px rgba(0, 0, 0, 0.3);
}

.nav-link-alternate-active,
.nav-link-alternate-active:focus {
  font-weight: 800;
  color: white;
  text-shadow: 0.1px 0.1px 0.1px rgba(0, 0, 0, 0.3);
}

.nav-link-alternate-active:hover {
  font-weight: 800;
  color: rgb(235, 235, 235);
  text-shadow: 0.1px 0.1px 0.1px rgba(0, 0, 0, 0.3);
}

.adjust-right-nav {
  width: 100px;
  text-align: center;
  padding-right: 20px;
}

.collapsed {
  background-color: white;
}

.shopping-button {
  background-color: transparent;
  border: none;
  /* color: transparent; */
}

.shopping-icon {
  background-color: transparent;
}

.shopping-cart-container {
  position: absolute;
  min-width: 100dvw;
  min-height: 100dvh;
  max-height: 100dvh;
  max-width: 100dvw;
  overflow-y: hidden;
  /* max-width: 100%;
  max-height: 80%;
  width: 100%; */
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 10;
  display: flex;
}

.disable-interaction {
  background-color: black;
  opacity: 50%;
  flex: 9;
}

.shopping-cart-panel {
  background-color: white;
  flex: 3;
  display: flex;
  flex-direction: column;
}

.closebtn {
  display: none;
}

@media screen and (max-width: 992px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    /* Adjusted to the sidebar's actual width */
    height: 100vh;
    width: 250px;
    /* Set this to your desired width */
    background-color: #f1f1f1;
    /* Changed color */
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 10000;
  }

  .sidebar-open {
    left: 0;
  }

  .sidebar-delay {
    /* transition-delay: 2s; */
    margin-top: 3.5rem;
  }

  .sidebar a {
    padding: 15px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    border: 0;
    color: #818181;
    /* You can adjust the color */
    display: block;
  }
}

.close-cart {
  flex: 1;
  border-bottom: solid;
  border-color: rgb(186, 186, 186);
  border-width: 1px;
}

.cart-items {
  overflow: scroll;
  flex: 20;
  width: 40vw;
}

.empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 20;
  /* min-height: 30; */
}

.empty-message {
  padding: 50px;
  font-size: larger;
  color: gray;
}

/* For tablets */
@media only screen and (max-width: 980px) {
  .cart-items {
    overflow: scroll;
    flex: 20;
    width: 65vw;
  }

  .addRemoveSpan {
    padding: 5px 15px;
  }

  .empty-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 20;
    width: 85vw;
    width: 65vw;
    /* min-height: 30; */
  }

  .empty-message {
    padding: 50px;
    font-size: larger;
    color: gray;
    width: 85vw;
    width: 65vw;
  }
}

.item-description {
  font: Montserrat;
  width: 100%;
  text-align: left;
  font-size: 80%;
}

.item-title {
  font: Montserrat;
  font-weight: bold;
  width: 100%;
  text-align: left;
  font-size: 90%;
}

.cart-item-container {
  height: 35%;
  border-bottom: solid;
  border-color: rgb(186, 186, 186);
  border-width: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

@media only screen and (max-height: 550px) {
  .cart-item-container {
    height: 50vh;
  }
}

/* For mobile devices */
@media only screen and (max-width: 480px) {

  .cart-item-container {
    height: 30vh;
  }

  .cart-items {
    overflow: scroll;
    flex: 20;
    width: 85vw;
  }

  .item-description {
    font-size: 65%;
  }

  .item-title {
    font-size: 100%;
  }

  .empty-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 20;
    width: 85vw;
    /* min-height: 30; */
  }

  .empty-message {
    padding: 50px;
    font-size: larger;
    color: gray;
    width: 85vw;
  }

  .addRemoveSpan {
    padding: 3px 8px;
  }
}

.checkout-container {
  flex: 1;
}

.colbreak {
  flex: 1;
}

.item-picture {
  max-width: 35%;
  max-height: 60%;
  margin: auto;
  width: 35%;
  height: 75%;
  object-fit: contain;
  object-position: contain;
}

.item-details {
  min-height: 75%;
  /* max-height: 75%; */
  min-width: 50%;
  max-width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 6;
  /* border: solid; */
}

.item-descriptors {
  flex: 8;
}

.item-buttons {
  flex: 3;
  margin: 0;
  padding: 0;
  align-items: center;
  /* border: solid; */
}

.rowbreak {
  flex: 20;
}

.checkout {
  width: 80%;
  background-color: rgba(243, 111, 57, 1);
  color: white;
  font-weight: bold;
  margin: 5px;
  border: none;
}
.checkout:disabled {
  background-color: rgb(255, 192, 168);
}

#collapsed-menu-icon {
  order: 1;
  margin-right: 10px;
  /* Add some margin between the icon and logo */
  border: none;
  padding: 1px;
}
