.shopping-cart-button {
  border: transparent;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.shopping-cart {
  margin: 0;
  padding: 0;
}

#shopping-quantity {
  font-size: 5px;
}
