h1,
h2,
h3 {
  font-family: "Roboto", sans-serif;
}

* {
  font-family: "Montserrat";
}

.totalDonatedDisplay {
  font-weight: 800;
  color: #f36f39;
  font-size: 5vw;
}

.totalDonatedSubheading {
  font-weight: 800;
  color: black;
  font-size: 1.5vw;
  padding-bottom: 3%;
  padding-bottom: 4%;
}

#gfm-form {
  width: 50vh;
}

.sitesection {
  display: flex;
  padding-top: 3%;
  padding-bottom: 3%;
}

#pastpartnersection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text-box {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 901px) {
  .column {
    width: 100%;
  }

  .description {
    padding-right: 15px;
    min-height: 70%;
    max-height: 70%;
    overflow: scroll;
  }

  #pastpartnersection {
    padding-left: 10%;
  }
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 2em;
  cursor: pointer;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 2em;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .column {
    width: 50%;
  }

  .left-arrow {
    transform: translateX(-85%);
  }
  
  .right-arrow {
    transform: translateX(85%);
  }

  .mobile-section {
    display: flex;
    flex-direction: column;
    padding: 25px;
  }

  .description {
    padding: 15px;
    text-align: justify;
    overflow: scroll;
  }

  #pastpartnersection {
    padding: 0% 5% 2% 5%;
  }

  .donation-forms-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .donation-form {
    width: 100%;
    align-items: center;
  }

  .totalDonatedDisplay {
    font-size: 50px;
  }

  .totalDonatedSubheading {
    font-size: 20px;
  }
}

.slideshow {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  position: relative;
  max-height: 100%;
  max-width: 100dvw;
}

.slide {
  padding: 2%;
  width: 78%;
  /* height: 500px; */
  border: solid;
  border-color: gray;
  /* add drop shadow */
  box-shadow: 0px 0px 0px 0.5px #b8b8b8;
  position: relative;
}
