.footer {
  background-color: rgb(83, 191, 181, 0.7);
  bottom: 0%;
  width: 100%;
  margin-top: 100px;
  height: 100%;
}

.footer-main {
  display: flex;
  font-family: Montserrat;
  flex-direction: row;
  min-height: 300px;
}

.footer-col {
  margin: 0 100px;
  flex: 1;
  text-align: center;
}

#firstCol {
  margin: 0;
}

.footer-row {
  font-weight: 500;
  line-height: 2;
}

.footer-heading {
  margin-top: 50px;
  font-weight: BOLD;
  margin-bottom: 15px;
}

.links {
  padding-bottom: 10px;
}

.links a {
  color: rgb(28, 28, 28);
}

a {
  text-decoration: none;
  color: black;
}

.icon {
  margin-right: 10px;
  margin-left: 10px;
  border-width: 5px;
  border-color: blue;
}

.inline-icon {
  margin-left: -30px;
  margin-right: 14px;
}

@media (max-width: 768px) {
  .footer-main {
    flex-direction: column;
    align-items: left;
    padding-left: 50px;
  }

  .footer-col {
    margin: 10px 0;
    text-align: left;
  }
}
