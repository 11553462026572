.article-card {
  font-family: "Montserrat", sans-serif;
  color: #106673ff;
  margin: 100px;
  margin-top: 100px;
}

.article-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.article-caption {
  font-size: 16px;
  margin-bottom: 10px;
}

.article-grid {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 3rem;
}

@media (max-width: 767px) {
  .article-grid {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

body {
  font-family: "Montserrat", sans-serif;
}