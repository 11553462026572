* {
  font-family: "Montserrat", sans-serif !important;
}

.execs {
  margin: 0 1rem;
}

.exec-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.exec-tile {
  flex: 1 1 calc(33.33% - 10px);
  margin-bottom: 20px;
  box-sizing: border-box;
}

.exec-image {
  width: 350px;
  height: 350px;
  object-fit: cover;
  margin-bottom: -10px;
}

.exec-names {
  font-weight: bold;
  padding: 20px;
  font-size: 100%;
}

#title {
  padding: 30px;
}

@media (max-width: 767px) {
  .exec-grid-container {
    flex-direction: column;
  }

  .exec-tile {
    flex: 1 1 100%;
  }

  .exec-image {
    width: 90%;
  }
}

#table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3%;
  row-gap: 3%;
  width: 100%;
  margin-bottom: 130px;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#history {
  width: 80%;
  margin-top: 3rem;
  text-align: center;
}

h2 {
  font-weight: 700;
  margin: 2rem;
  padding: 2rem;
}

.video-container {
  position: relative;
  width: 60%;
  padding-bottom: 32%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#images {
  height: 350px;
  width: 100%;
}

.about-content {
  margin: 0 5rem;
}

.Banner {
  color: #f0554d;
}

.FAQ__question {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding-bottom: 10px;
  padding-top: 10px;

  border-bottom: solid;
  border-color: rgb(167, 167, 167);
}

.FAQ__question:hover {
  cursor: pointer;
}

.FAQ__answer--hidden {
  display: none;
}

.FAQ__answer {
  margin-top: 20px;
  text-align: left;
}

@media (max-width: 900px) {
  iframe {
    width: 90%;
  }

  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 50%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */
  }
  
  .about-content {
    margin: 0 1rem;
  }
}
