h1 {
  font-weight: 700;
  font-size: xx-large;
}

h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

#mainContainer {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 90%;
  margin: 0 auto;
}

#cont-form {
  width: 600px;
  height: 935px;
  border: none;
}
#address-map {
  width: 100%;
  height: 450px;
}

@media only screen and (max-width: 767px) {
  #cont-form {
    width: 90vw;
    margin-top: 100px;
  }
  #address-map {
    width: 90vw;
  }
}

#table {
  display: grid;
  grid-template-columns: 28% 28% 28%;
  grid-template-rows: 270px 270px 270px;
  column-gap: 3%;
  row-gap: 130px;
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 100px;
}

#text {
  margin-top: 25px;
  padding-left: 250px;
  padding-right: 250px;
}

#images {
  height: 350px;
  width: 100%;
}

#title {
  font-size: 40px;
  font-weight: bold;
}

#leftCol,
#rightCol {
  flex: 1;
  /* border: solid; */
  margin-top: 5%;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
}

.field-label {
  font-size: 16px;
  text-align: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}

#name,
#email {
  width: 100%;
}

#message {
  width: 100%;
  height: 200px;
}

.message-btn {
  margin-top: 20px;
  width: 100%;
  background-color: rgb(243, 110, 57);
  color: white;
  font-weight: bold;
  padding: 2%;
  border-radius: 7px;
  transition: transform 0.2s ease;
}

.message-btn:hover {
  background-color: #5ebfb5;
}
